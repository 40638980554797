<template>
  <div
    class="overflow-y-hidden"
    :style="wrapperStyles"
  >
    <!-- Desktop view -->
    <splitpanes
      v-if="$vuetify.breakpoint.mdAndUp"
      horizontal
      :push-other-panes="false"
      class="default-theme h-full"
      @resize="resizeMap = !resizeMap"
    >
      <pane
        size="65"
        max-size="75"
      >
        <!-- lazy because of problems with vuetify and vue-leaflet https://github.com/vue-leaflet/Vue2Leaflet/issues/96 -->
        <v-lazy class="h-full">
          <!-- Important to have wrapping div when using `v-lazy`, otherwise map layout will be broken -->
          <div class="h-full">
            <map-base-tracking-history
              class="h-full"
              :zoom-on="zoomOn"
              ref="mapTrackingHistory"
              @set-position-marker-radius="radius => setPositionMarkerRadius(radius)"
            >
              <template #controls>
                <expendable-side-nav
                  :keep-expanded="keepExpanded"
                  :ignore-internal-selection="true"
                >
                  <v-tabs height="35px">
                    <v-tab class="tracking-tab" active-class="tracking-tab-active">
                      {{ $t('satellite-tracking/history.choose_vehicles') }}
                    </v-tab>
                    <v-tab class="tracking-tab" active-class="tracking-tab-active">
                      {{ $t('satellite-tracking/history.options') }}
                    </v-tab>
                    <v-tab-item>
                      <vehicles-and-dates-picker
                        v-model="selection"
                        class="mt-1 mb-2"
                        @dialog-open-status="dialogOpenStatus = $event"
                        @vehicle-selected-status="vehiclesSelectedStatus = $event"
                      />
                    </v-tab-item>
                    <v-tab-item class="pa-2">
                      <map-controls />
                    </v-tab-item>
                  </v-tabs>
                </expendable-side-nav>
              </template>
            </map-base-tracking-history>
          </div>
        </v-lazy>
      </pane>
      <pane
        size="35"
        min-size="25"
        max-size="80"
      >
        <trip-list-table
          class="h-full"
          :selection="selection"
          :trips-loaded="tripsLoaded"
          @selection-trips="trips => selectionTrips(trips)"
        />
      </pane>
    </splitpanes>
    <!-- Mobile view -->
    <div
      v-else
      class="h-full"
    >
      <map-base-tracking-history
        ref="mapTrackingHistory"
        :zoom-on="zoomOn"
        :style="mapComponentStyles"
        class="animated-height"
        @set-position-marker-radius="radius => setPositionMarkerRadius(radius)"
      >
        <template #controls>
          <expendable-side-nav
            :keep-expanded="keepExpanded"
            :ignore-internal-selection="true"
          >
            <v-tabs height="35px">
              <v-tab class="tracking-tab" active-class="tracking-tab-active">
                {{ $t('satellite-tracking/history.choose_vehicles') }}
              </v-tab>
              <v-tab class="tracking-tab" active-class="tracking-tab-active">
                {{ $t('satellite-tracking/history.options') }}
              </v-tab>
              <v-tab-item>
                <vehicles-and-dates-picker
                  v-model="selection"
                  class="mt-1 mb-2"
                  @dialog-open-status="dialogOpenStatus = $event"
                  @vehicle-selected-status="vehiclesSelectedStatus = $event"
                />
              </v-tab-item>
              <v-tab-item class="pa-2">
                <map-controls />
              </v-tab-item>
            </v-tabs>
          </expendable-side-nav>
        </template>
      </map-base-tracking-history>
      <!-- Expand button -->
      <v-btn
        v-show="!expandedView"
        block
        height="35"
        color="white"
        class="rounded-0 elevation-0 mb-n3"
        @click="expandedView = !expandedView"
      >
        <v-icon
          color="primary"
          size="40"
        >
          {{ expandButtonIcon }}
        </v-icon>
      </v-btn>
      <trip-list-table
        show-expand-button
        :selection="selection"
        :trips-loaded="tripsLoaded"
        :style="tripListStyles"
        class="animated-height"
        @toggle-expanded="expandedView = $event"
        @selection-trips="trips => selectionTrips(trips)"
      >
        <template #expand_button>
          <!-- Collapse button -->
          <v-btn
            block
            height="38"
            color="white"
            class="rounded-0 elevation-0 mb-0 pt-1"
            @click="expandedView = !expandedView"
          >
            <v-icon
              color="primary"
              size="40"
            >
              {{ expandButtonIcon }}
            </v-icon>
          </v-btn>
        </template>
      </trip-list-table>
    </div>

    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="60"
      />
    </v-overlay>
  </div>
</template>

<script>
import MapBaseTrackingHistory from '@/modules/satellite-tracking-module/tracking-history/components/MapBaseTrackingHistory'
import MapControls from '@/modules/satellite-tracking-module/tracking-history/components/MapControls'
import TripListTable from '@/modules/satellite-tracking-module/tracking-history/components/TripListTable'
import VehiclesAndDatesPicker from '@/modules/satellite-tracking-module/tracking-history/components/VehiclesAndDatesPicker'
import ExpendableSideNav from '@/global/components/ExpendableSideNav'
import { Pane, Splitpanes } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import { createNamespacedHelpers } from 'vuex'
import { cloneDeep, debounce, isEmpty } from 'lodash'
import { removeHeightStylesFromTheMainElement, updatePageToBeFullHeightOfTheViewport } from '@/global/services/helpers/elementStyles'
import polyUtil from 'polyline-encoded'
import L, { latLng } from 'leaflet'
import dayjs from 'dayjs'
import { api } from '../../../../global/services/api'
import { formatSqlDateTime } from '@/global/services/helpers/dates'
import colors from 'vuetify/lib/util/colors'
const { mapGetters, mapActions } = createNamespacedHelpers('satellite-tracking/tracking-history')

export default {
  name: 'TrackingHistory',

  components: {
    MapBaseTrackingHistory,
    MapControls,
    TripListTable,
    VehiclesAndDatesPicker,
    ExpendableSideNav,
    Splitpanes,
    Pane
  },

  data () {
    return {
      positionMarkerRadius: 4,
      expandedView: false,
      routes: [],
      selection: {
        invalid: false,
        vehicles: [],
        from: null,
        to: null,
        company_scope_id: null
      },
      tripsLoaded: false,
      colorMapping: [],
      pickerActivator: null,
      resizeMap: false,
      dialogOpenStatus: false,
      vehiclesSelectedStatus: null,
      clickedRouteKey: null,
      icons: {
        startPoint: L.divIcon({
          className: 'l-marker-icon-route-start marker-icon',
          iconSize: [15, 15]
        }),
        endPoint: L.divIcon({
          className: 'l-marker-icon-route-end marker-icon',
          iconSize: [15, 15]
        }),
        pause: L.divIcon({
          className: 'l-marker-icon-route-pause marker-icon',
          iconSize: [15, 15]
        }),
        midPoint: L.divIcon({
          className: 'l-marker-icon-route-mid marker-icon',
          iconSize: [8, 8]
        }),
        sensorStartPoint: L.divIcon({
          className: 'l-marker-icon-sensor-start marker-icon',
          iconSize: [25, 33.3],
          iconAnchor: [12.5, 33.3]
        }),
        sensorEndPoint: L.divIcon({
          className: 'l-marker-icon-sensor-end marker-icon',
          iconSize: [25, 33.3],
          iconAnchor: [12.5, 33.3]
        })
      },
      routesPositions: [],
      tripsOnMap: [],
      actualTrips: [],
      alarmData: [],
      sensorData: [],
      sameLocationThreshold: 0.0001 // 11.1 meters
    }
  },

  computed: {
    ...mapGetters([
      'alarmIcons',
      'checkboxes',
      'loading',
      'viewConfig',
      'tripPositions',
      'selectedDigitalInputType',
      'sensorActivityData',
      'tripAlarms'
    ]),

    keepExpanded () {
      return this.dialogOpenStatus || !this.vehiclesSelectedStatus
    },

    zoomOn () {
      if (this.checkboxes.autoCenter && this.routes.length) {
        return this.routes.map(route => route.positions).flat()
      }
      return null
    },

    expandButtonIcon () {
      return this.expandedView
        ? 'mdi-chevron-down'
        : 'mdi-chevron-up'
    },

    wrapperStyles () {
      return {
        height: this.$vuetify.breakpoint.mdAndUp
          ? 'calc(var(--vh, 1vh) * 100 - 64px)'
          : 'calc(var(--vh, 1vh) * 100 - 56px)'
      }
    },

    mapComponentStyles () {
      return {
        height: this.expandedView ? '0%' : 'calc(100% - 35px)'
      }
    },

    tripListStyles () {
      return {
        height: this.expandedView ? '100%' : '0%'
      }
    },

    invalid () {
      return this.selection.invalid
    },

    vehicles () {
      this.setSelectedVehiclesCount(this.selection.vehicles.length)
      return this.selection.vehicles
    },

    from () {
      return this.selection.from
    },

    to () {
      return this.selection.to
    },

    companyScopeId () {
      return this.selection.company_scope_id
    }
  },

  watch: {
    // We debounce the map resize to prevent the map from resizing too often because the resizeMap event is triggered on pixel change
    resizeMap: debounce(function (newVal) {
      this.$refs?.mapTrackingHistory?.$refs?.mapBase?.$refs?.map?.mapObject?.invalidateSize()
    }, 200),

    // Load trips when a selection is made
    selection: {
      deep: true,
      handler () {
        if (!this.invalid) {
          this.selectedTrips = []

          this.fetchTrips({
            vehicle_ids: this.vehicles.map(vehicle => vehicle.id),
            from: this.from,
            to: this.to
          }).then(() => {
            this.tripsLoaded = true
            this.colorMapping = this.getColors()
          })

          this.updateCheckboxesWithoutSaving({ field: 'showRouteAlarms', value: false })
          this.updateCheckboxesWithoutSaving({ field: 'showDinStatus', value: false })
          this.clearMapControlInputs()

          this.setDateRange({
            from: this.from,
            to: this.to
          })

          if (this.selectedDigitalInputType && this.checkboxes.showRouteAlarms.showDinStatus) {
            this.fetchSensorActivityData({
              params: {
                vehicle_ids: this.vehicles.map(vehicle => vehicle.id),
                selected_digital_input_type: this.selectedDigitalInputType,
                from: this.from,
                to: this.to,
                company_scope_id: this.companyScopeId
              }
            })
          }
          if (this.checkboxes.showRouteAlarms) {
            this.fetchTripAlarms({
              params: {
                vehicle_ids: this.vehicles.map(vehicle => vehicle.id),
                date_time_start: this.from,
                date_time_end: this.to,
                company_scope_id: this.companyScopeId
              }
            })
          }
        }
      }
    },

    selectedDigitalInputType: {
      deep: true,
      handler () {
        if (this.selectedDigitalInputType && this.checkboxes.showDinStatus) {
          this.fetchSensorActivityData({
            params: {
              vehicle_ids: this.vehicles.map(vehicle => vehicle.id),
              selected_digital_input_type: this.selectedDigitalInputType,
              from: this.from,
              to: this.to
            }
          })
        }
      }
    },

    checkboxes: {
      deep: true,
      async handler () {
        try {
          if (this.checkboxes.showRouteAlarms) {
            this.startLoader()
            await this.fetchTripAlarms({
              params: {
                vehicle_ids: this.vehicles.map(vehicle => vehicle.id),
                date_time_start: this.from,
                date_time_end: this.to,
                company_scope_id: this.companyScopeId
              }
            })
          }
          else {
            this.$refs?.mapTrackingHistory?.$refs?.mapBase?.removeMarkersFromPolyline(this.alarmData?.map(alarm => alarm.routeKey + '_' + alarm.id))
          }
          if (this.selectedDigitalInputType && this.checkboxes.showDinStatus) {
            this.fetchSensorActivityData({
              params: {
                vehicle_ids: this.vehicles.map(vehicle => vehicle.id),
                selected_digital_input_type: this.selectedDigitalInputType,
                from: this.from,
                to: this.to
              }
            })
          }
          else {
            if (this.sensorData && this.sensorData.length) {
              this.$refs?.mapTrackingHistory?.$refs?.mapBase?.deletePolylines(this.sensorData?.map((obj, index) => obj.routeKey + 'additional'), this.checkboxes.autoCenter)
            }
          }
        }
        catch (e) {
          console.log(e)
        }
      }
    },

    positionMarkerRadius (radius) {
      this.$refs?.mapTrackingHistory?.$refs?.mapBase?.changeAllCircleMarkersRadius(radius)
    },

    actualTrips: {
      immediate: true,
      deep: true,
      async handler () {
        if (this.checkboxes.showRouteAlarms) {
          await this.fetchTripAlarms({
            params: {
              vehicle_ids: this.vehicles.map(vehicle => vehicle.id),
              date_time_start: this.from,
              date_time_end: this.to,
              company_scope_id: this.companyScopeId
            }
          })
        }
        if (this.checkboxes.showDinStatus) {
          await this.fetchSensorActivityData({
            params: {
              vehicle_ids: this.vehicles.map(vehicle => vehicle.id),
              selected_digital_input_type: this.selectedDigitalInputType,
              from: this.from,
              to: this.to
            }
          })
        }
      }
    },

    tripAlarms: {
      immediate: true,
      deep: true,
      handler (val) {
        if (this.actualTrips && this.actualTrips.length && val) {
          const result = []
          this.actualTrips.forEach(route => {
            let alarmsDataUnfiltered = []
            try {
              alarmsDataUnfiltered = val.alarms[route.vehicle.id] ?? []
            }
            catch (e) {
              return null
            }
            const tripStartDayjs = dayjs(route.startTimeUnformatted)
            const tripEndDayjs = dayjs(route.endTimeUnformatted)

            alarmsDataUnfiltered.filter(function (item) {
              const date = dayjs(item.dateTimeUnformated)
              if (date.isBefore(tripEndDayjs) && date.isAfter(tripStartDayjs)) {
                const modifiedItem = { ...item, routeKey: route.key, route: route }
                result.push(modifiedItem)
                return true
              }
            })
          })

          if (result.length) {
            const additionalMarkers = []
            result.forEach(alarm => {
              additionalMarkers.push({
                coordinates: latLng(alarm.coordinates),
                popup: {
                  popupData: async () => await this.getAlarmTooltipData(alarm, alarm.route),
                  popupOptions: {
                    offset: [25, -40]
                  }
                },
                options: {
                  id: alarm.routeKey + '_' + alarm.id,
                  icon: this.alarmIcons[alarm.type]
                }
              })
            })
            this.alarmData = result
            if (additionalMarkers.length) {
              this.$refs?.mapTrackingHistory?.$refs?.mapBase?.addMarkersToPolyline(additionalMarkers)
            }
          }
        }
      }
    },

    sensorActivityData: {
      immediate: true,
      deep: true,
      async handler (val) {
        const results = []
        if (this.actualTrips && this.actualTrips.length && val) {
          for (const route of this.actualTrips) {
            let sensorDataUnfiltered = []
            try {
              sensorDataUnfiltered = val.sensorData[route.vehicle.id] ?? []
            }
            catch (e) {
              return results
            }
            if (sensorDataUnfiltered.length === 0) {
              return results
            }
            const tripStart = new Date(route.startTimeUnformatted)
            const tripEnd = new Date(route.endTimeUnformatted)
            let gotFirstSensor = false
            let previousSensorIndex = null

            const sensorData = sensorDataUnfiltered.filter(function (item, index) {
              const date = new Date(item.date_time)
              if (date <= tripEnd && date >= tripStart) {
                if (!gotFirstSensor) {
                  previousSensorIndex = index - 1
                  gotFirstSensor = true
                }
                return true
              }
            })

            if (sensorData.length === 0) {
              previousSensorIndex = null
              sensorDataUnfiltered.forEach(function (item, index) {
                const date = new Date(item.date_time)
                if (date <= tripStart) {
                  previousSensorIndex = index
                }
              })
              sensorData.push(sensorDataUnfiltered[previousSensorIndex])
            }
            else {
              sensorData.unshift(sensorDataUnfiltered[previousSensorIndex])
            }

            // Return empty results if no sensor data
            if (sensorData.length === 0) {
              return results
            }

            let startPoint = null
            let endPoint = null
            let positions = []

            let sensorActivityStartDate = null
            let sensorActivityEndDate = null
            let sensorDataIndex = 0

            // Function to set sensor activity start and end times
            const getSensorActivityTimes = () => {
              // Find first next sensor item with active status
              const activeSensorItem = sensorData.find((item, index) => {
                if (item) {
                  return item.sensor_active && index >= sensorDataIndex
                }
              })
              // Set sensor activity start date to the first active item, null otherwise
              sensorActivityStartDate = activeSensorItem
                ? new Date(activeSensorItem.date_time)
                : null
              // Increment the index so that we skip previous sensor items
              sensorDataIndex++
              // Find first next sensor item with inactive status
              const inactiveSensorItem = sensorData.find((item, index) => {
                if (item) {
                  return !item.sensor_active && index >= sensorDataIndex
                }
              })
              // Set sensor activity end date to the first active item
              if (inactiveSensorItem) {
                sensorActivityEndDate = new Date(inactiveSensorItem.date_time)
              }
              // Otherwise, use trip end date if sensor start date is set, null if not
              else {
                sensorActivityEndDate = sensorActivityStartDate ? tripEnd : null
              }
              // Increment the index so that we skip previous sensor items
              sensorDataIndex++
            }

            getSensorActivityTimes()

            if (!sensorActivityStartDate && !sensorActivityEndDate) {
              return results
            }

            // Function to set end point of sensor activity, push to results array and reset data
            const finishSensorActivityPeriod = (position) => {
              endPoint = {
                lat: position.lat,
                lng: position.lng,
                address: position.address,
                dateTime: position.dateTime
              }

              if (tripEnd <= sensorActivityEndDate) {
                endPoint = {
                  lat: route.endLat,
                  lng: route.endLng,
                  address: route.endAddress,
                  dateTime: this.parseTripDateTime(route.endTimeUnformatted)
                }
              }

              positions.push({
                lat: endPoint.lat,
                lng: endPoint.lng
              })

              results.push({
                startPoint,
                endPoint,
                positions,
                routeKey: route.key,
                route: route,
                startTimeUnformatted: route.startTimeUnformatted,
                endTimeUnformatted: route.endTimeUnformatted,
                vehicle: {
                  id: route.vehicle.id
                },
                startTime: route.startTime,
                endTime: route.endTime,
                key: route.key
              })

              startPoint = null
              endPoint = null
              positions = []

              getSensorActivityTimes()
            }
            await this.fetchTripPositionsHelper(route)
            const sensorPositionsData = cloneDeep(this.tripPositions)

            const positionsCount = sensorPositionsData.positions[route?.vehicle?.id]?.length

            /* eslint-disable no-unmodified-loop-condition */
            while (sensorActivityStartDate || sensorActivityEndDate) {
              let lastPosition = null
              // edge case
              if (!positionsCount) {
                startPoint = {
                  lat: route.endlat,
                  lng: route.endLng,
                  address: route.endAddress,
                  dateTime: sensorActivityStartDate
                }
                finishSensorActivityPeriod({
                  lat: route.endLat,
                  lng: route.endLng,
                  address: route.endAddress,
                  dateTime: sensorActivityEndDate
                })
              }
              let shouldSkip = false

              sensorPositionsData.positions[route?.vehicle?.id]?.forEach((position, index) => {
                if (shouldSkip) {
                  return
                }
                const positionDate = new Date(position.dateTime)
                if (lastPosition) {
                  const lastPositionDateTime = new Date(lastPosition.dateTime)
                  if ((sensorActivityStartDate >= lastPositionDateTime && sensorActivityStartDate < positionDate) || sensorActivityStartDate <= tripStart) {
                    if (!startPoint) {
                      startPoint = {
                        lat: lastPosition.lat,
                        lng: lastPosition.lng,
                        address: lastPosition.address,
                        dateTime: sensorActivityStartDate
                      }
                    }
                    positions.push({
                      lat: lastPosition.lat,
                      lng: lastPosition.lng
                    })
                  }

                  if (lastPositionDateTime >= sensorActivityStartDate && lastPositionDateTime <= sensorActivityEndDate) {
                    positions.push({
                      lat: lastPosition.lat,
                      lng: lastPosition.lng
                    })
                  }

                  if (sensorActivityEndDate >= lastPositionDateTime && sensorActivityEndDate < positionDate && startPoint) {
                    finishSensorActivityPeriod({
                      lat: lastPosition.lat,
                      lng: lastPosition.lng,
                      address: lastPosition.address,
                      dateTime: sensorActivityEndDate
                    })
                    shouldSkip = true
                    return
                  }
                  // check if last position
                  if (index + 1 === positionsCount) {
                    if (!startPoint) {
                      startPoint = {
                        lat: position.lat,
                        lng: position.lng,
                        address: position.address,
                        dateTime: sensorActivityStartDate
                      }
                      positions.push({
                        lat: startPoint.lat,
                        lng: startPoint.lng
                      })
                    }
                    finishSensorActivityPeriod({
                      lat: position.lat,
                      lng: position.lng,
                      address: position.address,
                      dateTime: sensorActivityEndDate
                    })
                    return
                  }
                }
                lastPosition = position
              })
            }
          }
        }

        if (results && results.length) {
          const polylineConfig = {
            polylines: [],
            markers: [],
            ignoreExisting: true,
            fitPolylines: this.checkboxes.autoCenter
          }

          results.forEach((data, index) => {
            if (data && data.positions) {
              polylineConfig.polylines.push(
                {
                  coordinates: data.positions,
                  options: {
                    id: data.routeKey + 'additional',
                    color: '#f4a734',
                    weight: '11'
                  },
                  polylineClick: () => this.handleRouteClick(data),
                  polylineDecoratorClick: () => this.handleRouteClick(data),
                  polylineMouseOver: (e) => this.mouseOverRoute(e, data.route),
                  polylineMouseOut: (e) => this.mouseOutRoute(e),
                  polylineDecoratorMouseOver: (e) => this.mouseOverRoute(e, data.route),
                  polylineDecoratorMouseOut: (e) => this.mouseOutRoute(e)
                }
              )
            }

            if (data && data.startPoint && data.startPoint.lat && data.startPoint.lng) {
              polylineConfig.markers.push(
                {
                  coordinates: data.startPoint,
                  popup: {
                    popupData: async () => await this.getSensorMarkerTooltipData(data, data.vehicle.id, true),
                    popupOptions: {
                      offset: [15, -20]
                    }
                  },
                  options: {
                    icon: this.icons.sensorStartPoint,
                    id: data.routeKey + 'additional' + '_sensorStartMarker'
                  }
                }
              )
            }

            if (data && data.endPoint && data.endPoint.lat && data.endPoint.lng) {
              polylineConfig.markers.push(
                {
                  coordinates: data.endPoint,
                  popup: {
                    popupData: async () => await this.getSensorMarkerTooltipData(data, data.vehicle.id),
                    popupOptions: {
                      offset: [15, -20]
                    }
                  },
                  options: {
                    icon: this.icons.sensorEndPoint,
                    id: data.routeKey + 'additional' + '_sensorEndMarker'
                  }
                }
              )
            }
          })
          this.$refs?.mapTrackingHistory?.$refs?.mapBase?.generatePolylines(polylineConfig)
          this.sensorData = results // Store it globally, so we can delete polyline later when we need it
        }
      }
    }
  },

  beforeCreate () {
    // We need to set height styles manually, because `main` element is located
    // in the parent component, so we can't set its styles from the child component
    updatePageToBeFullHeightOfTheViewport()
  },

  beforeDestroy () {
    // We need to unset styles manually before leaving the page (so it won't affect other index pages),
    // because `main` element is located in the parent component, so we can't set its styles from the child component
    removeHeightStylesFromTheMainElement()
  },

  async created () {
    await this.fetchConfig()
  },

  methods: {
    ...mapActions([
      'fetchConfig',
      'fetchTrips',
      'setSelectedVehicles',
      'setSelectedVehiclesCount',
      'setCompanyScopeId',
      'fetchSensorActivityData',
      'fetchTripPositions',
      'fetchTripAlarms',
      'updateCheckboxesWithoutSaving',
      'setSelectedDigitalInputType',
      'setDateRange',
      'startLoader'
    ]),

    formatSqlDateTime,

    parseTripDateTime (dateTime) {
      return dayjs(dateTime, 'DD.MM.YYYY. HH:mm:ss').toISOString()
    },

    async fetchTripPositionsHelper (route) {
      await this.fetchTripPositions({
        params: {
          vehicle_ids: [route.vehicle.id],
          from: route.startTime,
          to: route.endTime
        }
      })
    },

    endMarkerPoint (route) {
      if (this.repositionEndMarker) {
        const { startLat, startLng, endLat, endLng } = route

        // Check if start and end point are the same, or in the given threshold distance
        if (
          (startLat === endLat && startLng === endLng) ||
          Math.abs(startLat - endLat) <= this.sameLocationThreshold ||
          Math.abs(startLng - endLng) <= this.sameLocationThreshold
        ) {
          // Return new LatLng object moved to the right side of the original position
          return new window.L.LatLng(endLat, endLng + 0.00009)
        }
      }

      return {
        lat: route.endLat,
        lng: route.endLng
      }
    },

    // Only one route can have midpoints simultaneously
    async handleRouteClick (route) {
      try {
        // When clicking on the same route a second time, we first delete any existing midpoints and then add new ones
        if (this.clickedRouteKey === route.key) {
          if (this.routesPositions && this.routesPositions.length) {
            this.routesPositions.forEach(position => {
              if (position.positions && position.positions.length) {
                this.$refs?.mapTrackingHistory?.$refs?.mapBase?.removeMarkersFromPolyline(position.positions.map(obj => route.key + '_' + obj.id))
              }
            })
            this.clickedRouteKey = null
          }
          else {
            const additionalMarkers = []
            this.startLoader()

            await this.fetchTripPositionsHelper(route)

            // Invoke this method to filter out position data retrieved from the above API call and populate the global variable, 'this.routesPositions'
            this.positionsData(route, this.tripPositions)

            const positionsData = this.routesPositions.find(item => item.routeKey === route.key)
            if (positionsData && positionsData.positions.length) {
              positionsData.positions.forEach(tripPosition => {
                additionalMarkers.push({
                  type: 'circle',
                  popup: {
                    popupData: async () => await this.getPositionMarkerTooltipData(tripPosition, route)
                  },
                  coordinates: { lat: tripPosition.lat, lng: tripPosition.lng },
                  options: {
                    id: route.key + '_' + tripPosition.id,
                    icon: this.icons.midPoint,
                    radius: this.positionMarkerRadius,
                    color: '#0D47A1',
                    fillColor: '#fff',
                    pane: 'markerPane',
                    fillOpacity: '1'
                  }
                })
              })
              if (additionalMarkers.length) {
                this.$refs?.mapTrackingHistory?.$refs?.mapBase?.addMarkersToPolyline(additionalMarkers)
                this.clickedRouteKey = route.key
              }
            }
          }
        }
        // Otherwise, we clicked route for the first time
        else {
          if (this.routesPositions && this.routesPositions.length) {
            this.routesPositions.forEach(position => {
              if (position.positions && position.positions.length) {
                this.$refs?.mapTrackingHistory?.$refs?.mapBase?.removeMarkersFromPolyline(position.positions.map(obj => this.clickedRouteKey + '_' + obj.id))
              }
            })
            this.clickedRouteKey = null
          }
          const additionalMarkers = []
          this.startLoader()

          await this.fetchTripPositionsHelper(route)

          this.positionsData(route, this.tripPositions)
          const positionsData = this.routesPositions.find(item => item.routeKey === route.key)

          if (positionsData && positionsData.positions.length) {
            positionsData.positions.forEach(tripPosition => {
              additionalMarkers.push({
                type: 'circle',
                popup: {
                  popupData: async () => await this.getPositionMarkerTooltipData(tripPosition, route)
                },
                coordinates: { lat: tripPosition.lat, lng: tripPosition.lng },
                options: {
                  id: route.key + '_' + tripPosition.id,
                  icon: this.icons.midPoint,
                  radius: this.positionMarkerRadius,
                  color: '#0D47A1',
                  fillColor: '#fff',
                  pane: 'markerPane',
                  fillOpacity: '1'
                }
              })
            })
            if (additionalMarkers.length) {
              this.$refs?.mapTrackingHistory?.$refs?.mapBase?.addMarkersToPolyline(additionalMarkers)
              this.clickedRouteKey = route.key
            }
          }
        }
      }
      catch (e) {
        console.log('Error occurred in tracking history2 -> handleRouteClick method', {
          error: e,
          route: route
        })
      }
    },

    positionsData (route, tripPositions) {
      try {
        this.routesPositions = []
        const positionsDataUnfiltered = tripPositions.positions[route.vehicle.id] ?? []
        const routePositions = {
          routeKey: route.key,
          positions: cloneDeep(positionsDataUnfiltered)
        }
        if (routePositions.positions.length) {
          this.routesPositions.push(routePositions) // Keep in global variable, so we can use when we need to delete mid-route positions later
        }
      }
      catch (e) {
        console.log('Error occurred while getting route mid positions in trackingHistory component', {
          error: e,
          positions: tripPositions,
          route: route
        })
        return null
      }
    },

    // Main method for drawing polylines
    selectionTrips (trips) {
      try {
        const polylineConfig = {
          polylines: [],
          markers: [],
          fitPolylines: true
        }
        let tripsToAdd = trips

        // When fresh trips come, we need to determine if some trips are deleted or not
        // TripsOnMap variable keep currently visible trips on map
        if (this.tripsOnMap && this.tripsOnMap.length) {
          const newTrips = trips.map(trip => trip.key)
          tripsToAdd = trips.filter(trip => !this.tripsOnMap.includes(trip.key))
          const tripsToDelete = this.tripsOnMap.filter(trip => !newTrips.includes(trip))
          if (tripsToDelete && tripsToDelete.length) {
            this.$refs?.mapTrackingHistory?.$refs?.mapBase?.deletePolylines(tripsToDelete, this.checkboxes.autoCenter)
          }
          this.routesPositions = this.routesPositions.filter(item => !tripsToDelete.includes(item.routeKey))
        }

        tripsToAdd.forEach(route => {
          polylineConfig.polylines.push({
            coordinates: polyUtil.decode(route.polyline),
            options: {
              id: route.key,
              color: route.vehicle?.id ? this.colorMapping[route.vehicle.id] : '#3388ff',
              weight: 10
            },
            polylineClick: () => this.handleRouteClick(route),
            polylineDecoratorClick: () => this.handleRouteClick(route),
            polylineMouseOver: (e) => this.mouseOverRoute(e, route),
            polylineMouseOut: (e) => this.mouseOutRoute(e),
            polylineDecoratorMouseOver: (e) => this.mouseOverRoute(e, route),
            polylineDecoratorMouseOut: (e) => this.mouseOutRoute(e)
          })
          polylineConfig.markers.push({
            route: route,
            coordinates: {
              lat: route.startLat,
              lng: route.startLng
            },
            options: {
              icon: this.icons.startPoint,
              id: route.key + '_startMarker'
            }
          },
          {
            route: route,
            coordinates: this.endMarkerPoint(route),
            options: {
              icon: this.icons.endPoint,
              id: route.key + '_endMarker'
            }
          })
          if (route.pauses && route.pauses.length) {
            route.pauses.forEach((pause, index) => {
              polylineConfig.markers.push({
                pause: pause,
                route: route,
                coordinates: { lat: pause.lat, lng: pause.lng },
                options: {
                  icon: this.icons.pause,
                  id: route.key + '_' + index
                }
              })
            })
          }
        })
        polylineConfig.markers.forEach(marker => {
          marker.popup = {
            popupData: async () => await this.getMarkersTooltipData(marker)
          }
        })
        this.$refs?.mapTrackingHistory?.$refs?.mapBase?.generatePolylines(polylineConfig)
        this.tripsOnMap = trips.map(trip => trip.key)
        this.actualTrips = trips
      }
      catch (e) {
        console.log('Error occurred while drawing polylines in TrackingHistory component', {
          error: e,
          trips: trips
        })
      }
    },

    mouseOverRoute (event, route) {
      if (!route || this.clickedRouteKey === route.key) return
      const polyline = event.target
      const vehicleRegistration = route && route.vehicle && route.vehicle.registration ? route.vehicle.registration : ''

      const popup = L.popup()
        .setLatLng(event.latlng)
        .setContent(this.$t('satellite-tracking/live_tracking.live_route_on_hover_text').replace(':registration', '<b>' + vehicleRegistration + '</b>'))

      polyline.bindPopup(popup, { closeButton: false })

      popup.openOn(this.$refs?.mapTrackingHistory?.$refs?.mapBase?.$refs?.map?.mapObject)
    },

    mouseOutRoute (e) {
      e.target.closePopup()
    },

    async getMarkersTooltipData (marker) {
      try {
        if (!marker || isEmpty(marker)) {
          return `<b>${this.$t('satellite-tracking/live_tracking.live_route_positions_unavailable')}</b>`
        }

        const params = {
          ...marker.coordinates,
          vehicleId: marker.route && marker.route.vehicle && marker.route.vehicle.id && marker.route.vehicle.id.toString().trim() !== '' ? marker.route.vehicle.id : null
        }
        let routePositionText = `<b>${this.$t('satellite-tracking/live_tracking.live_route_positions_unavailable')}</b>`
        let routeTime = ''
        let parkingTime = ''
        let address = ''

        // If marker is route start marker
        if (marker.options.id.toString().includes('startMarker')) {
          routePositionText = this.$t('satellite-tracking/map.route_start')
          routeTime = `<b>${this.$t('satellite-tracking/report.start_time')}:</b> ${marker.route.startTime}`
          parkingTime = marker.route.startMarkerParkingTime
          address = marker.route && marker.route.startAddress ? marker.route.startAddress : ''
        }
        // Else if marker is route end marker
        else if (marker.options.id.toString().includes('endMarker')) {
          routePositionText = this.$t('satellite-tracking/map.route_end')
          routeTime = `<b>${this.$t('satellite-tracking/tracking-history.end_time')}:</b> ${marker.route.endTime}`
          parkingTime = marker.route.endMarkerParkingTime
          address = marker.route && marker.route.endAddress ? marker.route.endAddress : ''
        }
        // Else, marker is a pause marker
        else {
          routePositionText = this.$t('satellite-tracking/map.pause')
          routeTime = `<b>${this.$t('satellite-tracking/history.pause_start_time')}:</b> ${marker.pause.startTime} <br>
                       <b>${this.$t('satellite-tracking/history.pause_duration')}:</b> ${marker.pause.duration}`
          address = marker.pause && marker.pause.address ? marker.pause.address : ''
        }

        // If address is empty at this point that means that address is not populated in marker object, then use reverse geocoding logic to find address
        if (!address || address.toString().trim() === '') {
          const response = await api()['satellite-tracking'].get('reverse-geocode', params)
          if (response && response.data && response.data.address && response.data.address.toString().trim() !== '') {
            address = response && response.data && response.data.address ? response.data.address : ''
          }
        }

        let tooltipContent = `<div style="text-align: center; justify-content: center; font-weight: bold;">${routePositionText}</div>`

        if (routeTime && routeTime.toString().trim() !== '') {
          tooltipContent += `${routeTime} <br>`
        }
        if (parkingTime && parkingTime.toString().trim() !== '') {
          // This part is used to bold label in parkingTime value.
          // Parking time value is in format: label: H:m:s
          const parts = typeof parkingTime === 'string' ? parkingTime.split(': ') : null
          if (parts && parts.length === 2) {
            tooltipContent += `<b>${parts[0]}</b>: ${parts[1]} <br>`
          }
        }
        if (address && address.toString().trim() !== '') {
          tooltipContent += `<b>${this.$t('satellite-tracking/history.pause_address')}:</b> ${address}`
        }

        return tooltipContent
      }
      catch (e) {
        console.log('Error while getting route marker tooltip data in TrackingHistory component', {
          error: e,
          marker: marker
        })
        return `<b>${this.$t('satellite-tracking/live_tracking.live_route_positions_unavailable')}</b>`
      }
    },

    // Get mid-route position tooltip data
    async getPositionMarkerTooltipData (positionData, route) {
      try {
        if (!positionData || isEmpty(positionData)) {
          return `<b>${this.$t('satellite-tracking/live_tracking.live_route_positions_unavailable')}</b>`
        }

        const params = {
          lat: positionData.lat ? positionData.lat : null,
          lng: positionData.lng ? positionData.lng : null,
          vehicleId: route && route.vehicle && route.vehicle.id ? route.vehicle.id : null
        }
        const response = params && params.lat && params.lng ? await api()['satellite-tracking'].get('reverse-geocode', params) : null
        const tooltipContentParts = [
          positionData && positionData.dateTime ? `<b>${this.$t('road-maintenance/patrol-service.date_and_time')}: </b>` + this.formatSqlDateTime(positionData.dateTime) : null,
          positionData && positionData.speed ? `<b>${this.$t('satellite-tracking/fuel_probe_report.vehicle_speed')}: </b>` + positionData.speed : null,
          response && response.data && response.data.address && response.data.address.toString().trim() !== '' ? `<b>${this.$t('satellite-tracking/history.pause_address')}: </b>` + response.data.address : null
        ]
        // Filter out null or empty values
        const filteredContentParts = tooltipContentParts.filter(part => part && part.toString().trim() !== '')

        return filteredContentParts && filteredContentParts.length ? filteredContentParts.join(' <br> ') : `<b>${this.$t('satellite-tracking/live_tracking.live_route_positions_unavailable')}</b>`
      }
      catch (e) {
        console.log('Error occurred while getting position marker tooltip data in TrackingHistory component', {
          error: e,
          positions_data: positionData,
          route: route
        })
        return `<b>${this.$t('satellite-tracking/live_tracking.live_route_positions_unavailable')}</b>`
      }
    },

    async getAlarmTooltipData (alarm, route) {
      try {
        if (!alarm && isEmpty(alarm)) {
          return `<b>${this.$t('satellite-tracking/live_tracking.live_route_positions_unavailable')}</b>`
        }

        const params = {
          ...alarm.coordinates,
          vehicleId: route.vehicle.id
        }
        const response = await api()['satellite-tracking'].get('reverse-geocode', params)
        const tooltipContentParts = []

        if (alarm.title && alarm.title.toString().trim() !== '') {
          tooltipContentParts.push(`<div style="font-weight: bold; text-align: center;">${alarm.title}</div>`)
        }
        if (alarm.text && alarm.text.toString().trim() !== '') {
          tooltipContentParts.push(`<b>Alarm: </b>${alarm.text} <br>`)
        }
        if (alarm.dateTime && alarm.dateTime.toString().trim() !== '') {
          tooltipContentParts.push(`<b>${this.$t('road-maintenance/patrol-service.date_and_time')}: </b>${alarm.dateTime}<br>`)
        }
        if (alarm.speed && alarm.speed.toString().trim() !== '') {
          tooltipContentParts.push(`<b>${this.$t('satellite-tracking/fuel_probe_report.vehicle_speed')}: </b>${alarm.speed}<br>`)
        }
        if (response && response.data && response.data.address && response.data.address.toString().trim() !== '') {
          tooltipContentParts.push(`<b>${this.$t('satellite-tracking/history.pause_address')}: </b>${response.data.address}`)
        }

        return tooltipContentParts && tooltipContentParts.length ? tooltipContentParts.join('') : `<b>${this.$t('satellite-tracking/live_tracking.live_route_positions_unavailable')}</b>`
      }
      catch (e) {
        console.log('Error occurred while getting tooltip data for alarm marker in TrackingHistory component', {
          error: e,
          alarm: alarm,
          route: route
        })
        return `<b>${this.$t('satellite-tracking/live_tracking.live_route_positions_unavailable')}</b>`
      }
    },

    async getSensorMarkerTooltipData (sensor, vehicleId, start) {
      try {
        if (!sensor || isEmpty(sensor)) {
          return `<b>${this.$t('satellite-tracking/live_tracking.live_route_positions_unavailable')}</b>`
        }

        const point = start ? (sensor && sensor.startPoint ? sensor.startPoint : null) : (sensor && sensor.endPoint ? sensor.endPoint : null)
        const title = start ? this.$t('satellite-tracking/map.sensor_operation_start') : this.$t('satellite-tracking/map.sensor_operation_end')
        const dateTime = point ? this.formatSqlDateTime(point.dateTime) : null
        const tooltipContentParts = []
        const params = {
          ...point,
          vehicleId
        }
        const response = await api()['satellite-tracking'].get('reverse-geocode', params)

        if (title && title.toString().trim() !== '') {
          tooltipContentParts.push(`<div style="font-weight: bold; text-align: center;">${title}</div>`)
        }
        if (dateTime && dateTime.toString().trim() !== '') {
          tooltipContentParts.push(`<b>${this.$t('road-maintenance/patrol-service.date_and_time')}: </b>${dateTime}<br>`)
        }
        if (response && response.data && response.data.address && response.data.address.toString().trim() !== '') {
          tooltipContentParts.push(`<b>${this.$t('satellite-tracking/history.pause_address')}: </b>${response.data.address}`)
        }

        return tooltipContentParts && tooltipContentParts.length ? tooltipContentParts.join('') : `<b>${this.$t('satellite-tracking/live_tracking.live_route_positions_unavailable')}</b>`
      }
      catch (e) {
        console.log('Error occurred while getting tooltip data for sensor marker in TrackingHistory component', {
          error: e,
          sensor: sensor,
          vehicleId: vehicleId
        })
        return `<b>${this.$t('satellite-tracking/live_tracking.live_route_positions_unavailable')}</b>`
      }
    },

    getVehicleType () {
      // we can explicitly take first element because in tracking history it is available
      // to select only one vehicle
      return this.selection.vehicles?.[0]?.type?.category
    },

    setPositionMarkerRadius (radius) {
      this.positionMarkerRadius = radius
    },

    clearMapControlInputs () {
      this.setSelectedDigitalInputType(null)
    },

    getColors () {
      const availableColors = [
        '#3388ff',
        colors.red.base,
        colors.orange.base,
        colors.yellow.base,
        colors.pink.accent1,
        colors.green.base,
        colors.cyan.accent3,
        colors.purple.base,
        colors.grey.base,
        colors.brown.base
      ]

      const colorMapping = []

      this.selection.vehicles.forEach(function (vehicle, index) {
        colorMapping[vehicle.id] = availableColors[index]
      })
      return colorMapping
    }
  }
}
</script>

<style scoped lang="scss">
#mainWrapper >>> .main-wrapper {
  height: calc(var(--vh, 1vh) * 100);
  max-height: calc(var(--vh, 1vh) * 100);
}

.animated-height {
  will-change: height;
  transition: height 0.25s ease-in;
}

.v-tabs--vertical > .v-tabs-bar .v-tab {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  height: auto;
  min-width: 1.7rem;
  width: 1.7rem;
  padding: 0.7rem 0.2rem;
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1.2rem;
  letter-spacing: normal;
  text-transform: none;
}
.v-tab--active {
  background: #E2E2E2;
}
::v-deep .v-tabs-slider-wrapper {
  transform: translateX(1.5rem);
  width: 3px !important;
  .v-tabs-slider {
    width: 3px !important;
    background-color: #6aac49;
  }
}

::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  background-color: #F8F8F8 !important;
  &.data-table-header {
    padding-top: 1.5rem !important;
    vertical-align: center;
    span:after {
      content: "\a";
      white-space: pre;
    }
  }
}
</style>
